import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Klees NDC Melbourne Journey",
  "path": "/",
  "date": "2022-06-22T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`NDC Melbourne - A Journey`}</h1>
    <p>{`Klee's trip to NDC Melbourne`}</p>
    <h2>{`Newcastle Airprot`}</h2>
    <h3>{`Arriving`}</h3>
    <p>{`Arrive at Newcastle Airport.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/98f393a174269518c6fe2ad3f923d96e/fd611/airport.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQMEAP/aAAwDAQACEAMQAAABrnl41XoqGGiRNRMEf//EABsQAQEBAAIDAAAAAAAAAAAAAAEAAgMhERIT/9oACAEBAAEFAjkvbLDi8x2/FjJdCYL/xAAXEQADAQAAAAAAAAAAAAAAAAAAARMQ/9oACAEDAQE/AZpEh5//xAAaEQABBQEAAAAAAAAAAAAAAAAAAhAREjET/9oACAECAQE/Abzp0E43/8QAGxAAAwACAwAAAAAAAAAAAAAAAAExEUEQMpH/2gAIAQEABj8ChtHZEXhjCKuIjZ//xAAeEAACAgICAwAAAAAAAAAAAAAAARExIZFBYXHB8P/aAAgBAQABPyGVZ1Y486SDWOXqCNMCcUz62ctV2Oh5oGlex//aAAwDAQACAAMAAAAQsxA//8QAFxEBAQEBAAAAAAAAAAAAAAAAARARMf/aAAgBAwEBPxARpHU//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAgEBPxBskEaf/8QAHxABAAICAAcAAAAAAAAAAAAAAQAhEUExUYGRodHw/9oACAEBAAE/ELg343CArrWGdsY5o7wZbl3qsgHmKYTChuGKo6Ctr6gUFQOZwgFenjP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Newcastle Airport",
            "title": "Newcastle Airport",
            "src": "/static/98f393a174269518c6fe2ad3f923d96e/66b66/airport.jpg",
            "srcSet": ["/static/98f393a174269518c6fe2ad3f923d96e/1fec3/airport.jpg 150w", "/static/98f393a174269518c6fe2ad3f923d96e/091cf/airport.jpg 300w", "/static/98f393a174269518c6fe2ad3f923d96e/66b66/airport.jpg 600w", "/static/98f393a174269518c6fe2ad3f923d96e/94576/airport.jpg 900w", "/static/98f393a174269518c6fe2ad3f923d96e/f1419/airport.jpg 1200w", "/static/98f393a174269518c6fe2ad3f923d96e/fd611/airport.jpg 2048w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Waiting`}</h3>
    <p>{`Wait for a plane with airport coffee $$$.
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/64b0c3ba082362454e8ba23d2657b535/fd611/airport-coffee.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQL/2gAMAwEAAhADEAAAAfMsmqlsajSHU2fINv/EABsQAAMAAgMAAAAAAAAAAAAAAAABAgMSIzIz/9oACAEBAAEFAmYfNQ6WtMiOOHESY+2p/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBH/2gAIAQMBAT8Bia7/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAACEBH/2gAIAQIBAT8BYy//xAAcEAACAgIDAAAAAAAAAAAAAAAAEQECEDEhQVH/2gAIAQEABj8COdMcI0WiyFGF6dn/xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMVFBgZH/2gAIAQEAAT8hEwSgGyy4KOjPEXyP3AmdQ9cdlFE9IZlOx//aAAwDAQACAAMAAAAQy+h8/8QAGBEBAQADAAAAAAAAAAAAAAAAAQAQETH/2gAIAQMBAT8QSOA7av/EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8QAlieWt//xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMUFRYXGBof/aAAgBAQABPxD2APuVr3ODUhlr4sENlf5n6xmUO6IE64yOVRuLt75mw6vmEv3oPq4s+LDZ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Airport Coffee",
            "title": "Airport Coffee",
            "src": "/static/64b0c3ba082362454e8ba23d2657b535/66b66/airport-coffee.jpg",
            "srcSet": ["/static/64b0c3ba082362454e8ba23d2657b535/1fec3/airport-coffee.jpg 150w", "/static/64b0c3ba082362454e8ba23d2657b535/091cf/airport-coffee.jpg 300w", "/static/64b0c3ba082362454e8ba23d2657b535/66b66/airport-coffee.jpg 600w", "/static/64b0c3ba082362454e8ba23d2657b535/94576/airport-coffee.jpg 900w", "/static/64b0c3ba082362454e8ba23d2657b535/f1419/airport-coffee.jpg 1200w", "/static/64b0c3ba082362454e8ba23d2657b535/fd611/airport-coffee.jpg 2048w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Hotel`}</h2>
    <h3>{`View`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3a3f1adc66310d37daec895d20377d61/66dfa/hotelview.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeJqsRqBPkAP/8QAGxAAAwACAwAAAAAAAAAAAAAAAQIRACEQEiL/2gAIAQEAAQUCkzrpht/GBkhldi3P/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAEQEQIhQZH/2gAIAQEABj8CLismmKznpvk//8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERITFBUXH/2gAIAQEAAT8hb9KFy3GaYsFxmPAipca9j8ky+RsrsQpT/9oADAMBAAIAAwAAABBUCAD/xAAWEQADAAAAAAAAAAAAAAAAAAARIFH/2gAIAQMBAT8QFT//xAAWEQEBAQAAAAAAAAAAAAAAAAARIDH/2gAIAQIBAT8QXI//xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhMUFRYXGh/9oACAEBAAE/EK663cq8Y+QA6rYPI50GSw723U3IYAv2rqWC6yLLKxortL5edwfRFT//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "view from the hotel room",
            "title": "view from the hotel room",
            "src": "/static/3a3f1adc66310d37daec895d20377d61/66b66/hotelview.jpg",
            "srcSet": ["/static/3a3f1adc66310d37daec895d20377d61/1fec3/hotelview.jpg 150w", "/static/3a3f1adc66310d37daec895d20377d61/091cf/hotelview.jpg 300w", "/static/3a3f1adc66310d37daec895d20377d61/66b66/hotelview.jpg 600w", "/static/3a3f1adc66310d37daec895d20377d61/94576/hotelview.jpg 900w", "/static/3a3f1adc66310d37daec895d20377d61/f1419/hotelview.jpg 1200w", "/static/3a3f1adc66310d37daec895d20377d61/66dfa/hotelview.jpg 4032w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      